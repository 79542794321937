@import "base";
html[is_mobile="false"] .page {
  position: relative;
  width: 100%;
  padding-top: $default-header-pc_height;
  overflow: hidden;
  background-color: $blue-10;

  gap: ptr(24);
  @include flex(center, center, column);

  .text_input {
    width: 100%;

    div {
      display: flex !important;
    }
  }

  .info {
    position: relative;
    max-width: ptr(708);
    @include flex(center, center, column);

    gap: ptr(24);
    padding-top: $default-header-pc_height;
    text-align: center;

    .title_wrapper {
      @include flex(center, center, column);

      gap: ptr(16);

      .title {
        color: $blue-100;
      }
    }

    .description_wrapper {
      @include flex(center, center, column);

      gap: ptr(8);
      color: $gray-80;
    }
  }

  .ad_res {
    position: relative;
    max-width: ptr(708);
    padding-bottom: ptr(80);

    .blue {
      color: $blue-100;
    }

    .necessary {
      @include flex(flex-end, flex-start);
      position: relative;
      width: 100%;
      margin-bottom: ptr(16);
      color: $blue-100;

      &.necessary_info {
        position: absolute;
        top: ptr(32);
        right: ptr(32);
      }
    }

    .mark {
      @include flex(flex-start, flex-start);

      margin: 0 ptr(6);
      padding-top: ptr(6);
    }

    .form {
      position: relative;
      width: 100%;
      @include flex(center, center, column);

      gap: ptr(32);

      .fieldset {
        position: relative;
        width: 100%;
        @include flex(center, flex-start, column);

        gap: ptr(32);
        padding: ptr(32);
        border-radius: ptr(24);
        background-color: $white;

        .noti {
          @include flex(flex-start, flex-start, column);

          padding: ptr(24);
          border-radius: ptr(8);
          gap: ptr(8);
          background-color: $gray-5;

          .noti_title {
            @include flex(flex-start, center);

            gap: ptr(4);

            svg {
              width: ptr(20);
              height: ptr(20);
            }
          }

          .noti_list {
            .noti_list_item {
              @include flex(flex-start, flex-start);

              color: $gray-80;
              list-style: none;
              gap: ptr(8);

              .bullet {
                @include flex(center, center);

                height: ptr(22);

                svg {
                  width: ptr(2);
                  height: ptr(2);
                }
              }
            }
          }
        }

        .portfolio_desc {
          padding-top: ptr(4);
          color: $gray-50;
        }
      }

      .form_submit {
        position: relative;
        @include flex(center, center);

        width: 100%;
        padding: ptr(16) ptr(24);
        border-radius: ptr(12);
        background-color: $blue-100;
        cursor: pointer;

        .form_submit_btn {
          width: 100%;
          color: $white;
        }

        &.disabled {
          background-color: $gray-5;

          .form_submit_btn {
            color: $gray-50;
          }
        }
      }
    }
  }

  .background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}

html[is_mobile="true"] .page {
  position: relative;
  width: 100%;
  padding: ptr(64) ptr(16) ptr(80);
  overflow: hidden;
  background-color: $blue-10;
  gap: ptr(24);
  @include flex(center, center, column);
  .text_input {
    width: 100%;

    div {
      display: flex !important;
    }
  }

  .info {
    z-index: 1;
    max-width: ptr(450);
    padding-top: $default-header-mo_height;
    text-align: center;
    gap: ptr(24);
    @include flex(center, center, column);

    .title_wrapper {
      @include flex(center, center, column);

      gap: ptr(16);

      .title {
        color: $blue-100;
      }
    }

    .description_wrapper {
      @include flex(center, center, column);

      gap: ptr(8);
      color: $gray-80;
    }
  }

  .ad_res {
    z-index: 1;
    width: 100%;

    .blue {
      color: $blue-100;
    }

    .necessary {
      @include flex(flex-end, flex-start);

      width: 100%;
      margin-bottom: ptr(16);
      color: $blue-100;

      &.necessary_info {
        position: absolute;
        top: ptr(24);
        right: ptr(24);
      }
    }

    .mark {
      @include flex(flex-start, flex-start);

      margin: 0 ptr(6);
      padding-top: ptr(6);
    }

    .form {
      width: 100%;
      @include flex(center, center, column);

      gap: ptr(32);

      .fieldset {
        position: relative;
        width: 100%;
        @include flex(center, flex-start, column);

        gap: ptr(32);
        padding: ptr(32);
        border-radius: ptr(24);
        background-color: $white;

        .noti {
          @include flex(flex-start, flex-start, column);

          padding: ptr(24);
          border-radius: ptr(8);
          gap: ptr(8);
          background-color: $gray-5;

          .noti_title {
            @include flex(flex-start, center);

            gap: ptr(4);

            svg {
              width: ptr(20);
              height: ptr(20);
            }
          }

          .noti_list {
            .noti_list_item {
              @include flex(flex-start, flex-start);

              color: $gray-80;
              list-style: none;
              gap: ptr(8);

              .bullet {
                @include flex(center, center);

                height: ptr(22);

                svg {
                  width: ptr(2);
                  height: ptr(2);
                }
              }
            }
          }
        }

        .portfolio_desc {
          padding-top: ptr(4);
          color: $gray-50;
        }
      }

      .form_submit {
        @include flex(center, center);

        width: 100%;
        padding: ptr(16) ptr(24);
        border-radius: ptr(12);
        background-color: $blue-100;

        .form_submit_btn {
          color: $white;
        }

        &.disabled {
          background-color: $gray-5;

          .form_submit_btn {
            color: $gray-50;
          }
        }
      }
    }
  }

  .background {
    position: absolute;
    z-index: 0;
    top: 0;
    left: 0;
    width: 100%;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}

html[is_mobile="false"] .confirm {
  @include flex(center, center, column);

  gap: ptr(16);

  .title {
    color: $gray-80;
  }

  .content {
    width: 100%;
    padding: ptr(16);
    border-radius: ptr(16);
    background-color: $blue-10;
    color: $blue-100;
    gap: ptr(4);
    @include flex(center, center, column);
  }
}
