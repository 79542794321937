@import "base";
@import "../../../../../../services/MarppleShop/shared/style/base";

.section_gap {
  margin-bottom: ptr(32);
}

.section_wrap {
  display: flex;
  flex-direction: column;
}

.min_height_unset {
  min-height: unset !important;
}
