@import "base";
html[is_mobile="false"] section.more {
  min-height: ptr(480);
  padding: 0 ptr(80);
  background: $blue-100;
  @include flex(center, center);
  .content {
    @include flex(center, center, column);
    gap: ptr(16);
    color: $white;
    .image_wrapper {
      width: ptr(240);
      height: ptr(200);
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
      }
    }
    .desc {
      text-align: center;
    }
    a.detail {
      @include flex(center, center);
      padding: ptr(16) ptr(24);
      border-radius: ptr(12);
      background-color: $blue-10;
      color: $blue-100;
      cursor: pointer;
    }
  }
}

html[is_mobile="true"] section.more {
  min-height: ptr(400);
  padding: ptr(34) ptr(20);
  background: $blue-100;
  @include flex(center, center);
  .content {
    @include flex(center, center, column);
    color: $white;
    .image_wrapper {
      width: ptr(240);
      height: ptr(200);
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
      }
    }
    .desc {
      text-align: center;
    }
    a.detail {
      @include flex(center, center);
      width: 100%;
      max-width: ptr(260);
      margin-top: ptr(16);
      padding: ptr(12) ptr(24);
      border-radius: ptr(8);
      background-color: $blue-10;
      color: $blue-100;
      cursor: pointer;
    }
  }
}
