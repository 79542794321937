@import "base";
html[is_mobile="false"] {
  .dropdown_button_wrap {
    padding: ptr(12) ptr(16) ptr(12) ptr(12);
  }
}

html[is_mobile="true"] {
  .dropdown_button_wrap {
    padding: 8px 8px 8px 12px;
  }
}

.form_dropdown {
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: stretch;
  overflow: hidden;
  -webkit-tap-highlight-color: transparent;

  .dropdown_wrapper {
    width: 100%;
    overflow: hidden;
    border: 1px solid $gray-10;
    border-radius: ptr(8);
  }

  &.is_absolute {
    .dropdown_wrapper {
      position: absolute;
      z-index: 1000;
      top: 0;
      right: 0;
      left: 0;
    }
  }

  .dropdown_button_wrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    &.inactive {
      background-color: $white;
      color: $gray-50;
      cursor: pointer;
    }

    &.active {
      background-color: $white;
      color: $black;
      cursor: pointer;
    }

    &.disabled {
      background-color: $gray-5;
      color: $gray-50;
      cursor: unset;
    }

    &.readonly {
      background-color: $gray-5;
      color: $black;
      cursor: unset;
    }
  }

  .dropdown_icon {
    width: ptr(16);
    height: ptr(16);
  }

  .unstyled_button {
    margin: 0;
    padding: 0;
    border: none;
    outline: none;
    background: inherit;
    background-color: transparent;
    box-shadow: none;
    color: inherit;
    font: inherit;
    line-height: inherit;
    text-align: inherit;
    appearance: none;

    &:focus {
      outline: none;
    }

    &::-moz-focus-inner {
      padding: 0;
      border: 0;
    }
  }

  .dropdown_button {
    width: 100%;
  }

  .dropdown_container {
    width: 100%;
    max-height: 0;
    overflow: hidden;
    transition:
      max-height 0.3s cubic-bezier(0.4, 0, 0.2, 1),
      opacity 0.2s ease-in-out;
    border: none;
    opacity: 0;
    background-color: $white;
    cursor: pointer;

    &.is_open {
      .dropdown_content {
        transform: translateY(0);
      }

      opacity: 1;

      html[is_mobile="true"] & {
        max-height: 190px;
      }

      html[is_mobile="false"] & {
        max-height: 200px;
      }
    }
  }

  .dropdown_content {
    overflow-y: auto;
    transform: translateY(-10px);
    transition: transform 0.3s cubic-bezier(0.4, 0, 0.2, 1);

    html[is_mobile="true"] & {
      max-height: 190px;
    }

    html[is_mobile="false"] & {
      max-height: 200px;
    }
  }

  .dropdown_item {
    display: flex;
    align-items: center;
    align-self: stretch;
    width: 100%;
    padding: ptr(8) ptr(12);
    gap: ptr(4);
    @include lineLimit(1);
  }
}
