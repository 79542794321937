@import "base";
$toggle-duration: 0.3s;
$apple-easing: cubic-bezier(0.4, 0, 0.2, 1);

html[is_mobile="true"] {
  .root {
    display: flex;
    flex-direction: column;
    width: 100%;
    row-gap: 24px;
  }

  .ProductInformationWrap {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .empty {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 120px 0;
    color: $gray-80;
  }

  .productSection {
    display: flex;
    justify-content: space-between;
    width: 100%;
    column-gap: 8px;

    .left {
      display: flex;
      flex: 1;
      flex-direction: column;
      row-gap: 8px;
      min-width: 0;
      overflow: hidden;
    }

    .product {
      display: flex;
      width: 100%;
      max-width: 100%;
      column-gap: 12px;

      .thumbnail {
        flex-shrink: 0;
        width: 76px;
        height: 76px;
        overflow: hidden;
        border-radius: 8px;
      }

      .info {
        display: flex;
        flex: 1;
        flex-direction: column;
        min-width: 0;
        overflow: hidden;
      }

      .productName {
        width: 100%;
        max-width: 100%;
        color: $black;

        &[data-state="open"] {
          display: -webkit-box;
          -webkit-line-clamp: 3;
          -webkit-box-orient: vertical;
          overflow: hidden;
          text-overflow: ellipsis;
          word-break: break-word;
        }

        &[data-state="close"] {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }

      .salesAmount {
        margin-top: 2px;
        color: $black;
      }

      .salesQuantity {
        color: $gray-80;
      }
    }

    .right {
      display: flex;
      flex-shrink: 0;
      align-items: center;
      justify-content: center;
      padding-right: 4px;

      .iconWrap {
        width: 20px;
        height: 20px;
        transform-origin: center;
        transition: transform $toggle-duration $apple-easing;
        will-change: transform;
        &[data-state="open"] {
          transform: rotate(0deg);
        }
        &[data-state="close"] {
          transform: rotate(-180deg);
        }
      }
    }
  }
  .ProductOptionSection {
    display: flex;
    box-sizing: border-box;
    flex-direction: column;
    width: 100%;
    height: 0;
    padding: 16px;
    border-radius: 8px;
    opacity: 0;
    background-color: $gray-5;
    row-gap: 8px;

    .row {
      display: flex;
      box-sizing: border-box;
      align-items: flex-start;
      justify-content: space-between;
      width: 100%;
    }

    .optionName {
      display: -webkit-box;
      flex: 1;
      max-width: 73%;
      overflow: hidden;
      color: $gray-80;
      text-overflow: ellipsis;
      word-break: break-word;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
    }

    .optionValue {
      display: flex;
      flex: 0 0 auto;
      flex-wrap: wrap;
      align-items: center;
      column-gap: 8px;
      color: $gray-80;
      text-align: right;
      word-break: keep-all;

      .divide {
        width: 1px;
        height: 14px;
        background-color: $gray-20;
      }
    }
  }
}
