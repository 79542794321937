@import "base";
html[is_mobile="false"] section.ad {
  position: relative;
  height: ptr(1080);
  background: $blue-10;
  @include flex(center, center);

  .image_list {
    position: relative;
    width: 100%;
    gap: ptr(24);
    @include flex(center, center);
    .image_item {
      position: relative;
      .image_wrapper {
        width: ptr(400);
        height: ptr(400);
        overflow: hidden;
        border-radius: ptr(40);
        & img,
        video {
          width: 100%;
          height: 100%;
          object-fit: cover;
          object-position: center;
        }
      }
      .speech_bubble_wrapper {
        position: absolute;
        z-index: 1;
        top: 0;
        left: 0;
        width: max-content;
        transform: translate(-50%, -50%);
        border-radius: ptr(16);
        background: $blue-100;
        .speech_bubble {
          position: relative;
          padding: ptr(16) ptr(48);
          color: $white;
          text-align: center;
          .bubble_tail {
            position: absolute;
            width: ptr(24);
            height: ptr(16);
            &.top {
              top: calc(100% - 2px);
              left: 50%;
            }
            &.top_right {
              top: calc(100% - 2px);
              left: 25%;
            }
            &.bottom_left {
              top: 2px;
              left: 60%;
              transform: translateY(-100%) rotate(180deg);
            }
          }
        }
      }
    }
  }

  .content {
    position: absolute;
    bottom: ptr(144);
    left: 50%;
    width: 100%;
    transform: translate(-50%, 0);
    color: $blue-100;
    text-align: center;
    gap: ptr(16);
    @include flex(center, center, column);
  }
}

html[is_mobile="true"] section.ad {
  position: relative;
  padding-top: ptr(96);
  padding-bottom: ptr(60);
  background: $blue-10;
  gap: ptr(60);
  @include flex(center, center, column);

  .image_list {
    position: relative;
    width: 100%;
    gap: ptr(24);
    @include flex(center, center, column);
    .image_item {
      @include flex(center, center);
      position: relative;
      .image_wrapper {
        width: 90%;
        overflow: hidden;
        border-radius: ptr(16);
        & img,
        video {
          width: 100%;
          height: 100%;
          object-fit: cover;
          object-position: center;
        }
      }
      .speech_bubble_wrapper {
        position: absolute;
        z-index: 1;
        top: 0;
        left: 0;
        width: max-content;
        transform: translate(-50%, -50%);
        border-radius: ptr(12);
        background: $blue-100;
        .speech_bubble {
          position: relative;
          padding: ptr(12) ptr(16);
          color: $white;
          text-align: center;
          .bubble_tail {
            position: absolute;
            width: ptr(20);
            height: ptr(14);
            &.top {
              top: calc(100% - 2px);
              left: 50%;
            }
            &.top_right {
              top: calc(100% - 2px);
              left: 25%;
            }
            &.top_left {
              top: calc(100% - 2px);
              left: 25%;
            }
            &.bottom_right {
              top: 2px;
              left: 20%;
              transform: translateY(-100%) rotate(180deg);
            }
            &.bottom_left {
              top: 2px;
              left: 60%;
              transform: translateY(-100%) rotate(180deg);
            }
          }
        }
      }
    }
  }

  .content {
    width: 100%;
    color: $blue-100;
    text-align: center;
    gap: ptr(16);
    @include flex(center, center, column);
  }
}
