@import "base";
html[is_mobile="false"] .page {
  width: 100%;

  .container {
    width: 100%;
    @include flex(flex-start, flex-start);

    gap: ptr(16);

    .content {
      width: 100%;
      min-width: ptr(1040);
      @include flex(flex-start, flex-start, column);

      gap: ptr(16);
    }
  }
}

html[is_mobile="true"] .page {
  width: 100%;

  .container {
    width: 100%;
    @include flex(flex-start, flex-start, column);

    gap: ptr(20);

    .content {
      width: 100%;
      @include flex(flex-start, flex-start, column);

      gap: ptr(20);
    }
  }
}
