@import "base";
@keyframes marquee-left {
  0% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(-100%);
  }
}

@keyframes marquee-right {
  0% {
    transform: translateX(-100%);
  }

  100% {
    transform: translateX(0);
  }
}

html[is_mobile="false"] section.creator {
  @include flex(center, center, column);
  padding: ptr(100) 0 ptr(90);
  background: $purple-100;
  gap: ptr(60);

  .content {
    @include flex(center, center, column);
    width: 100%;
    color: $pink-20;
    text-align: center;

    .data {
      @include flex(center, center);
      gap: ptr(16);
      padding: 0 0 ptr(32) ptr(40);
      font-size: ptr(200) !important;
      .cross_icon {
        width: ptr(80);
        height: ptr(80);
      }
    }
    .description {
      @include flex(center, center, column);
      gap: ptr(8);
      text-align: center;
      span {
        padding: ptr(4) ptr(12);
        border-radius: ptr(8);
        background: $pink-20;
        color: $purple-100;
      }
    }
  }

  .creators {
    position: relative;
    width: 100%;
    height: ptr(440);

    .marquee_wrapper {
      display: flex;
      position: absolute;
      z-index: 0;
      top: 0;
      width: 100%;
      overflow-x: hidden;
      opacity: 0.6;
      .marquee_background {
        display: flex;
        flex-shrink: 0;
        align-items: center;
        justify-content: space-around;
        min-width: 100%;
        height: ptr(200);
        max-height: ptr(200);
        animation: marquee-left 50s linear infinite;
        gap: ptr(32);
        will-change: transform;
        &[aria-hidden="true"] {
          margin-left: ptr(32);
        }
        img {
          width: ptr(200);
          height: ptr(200);
          border-radius: ptr(16);
        }
      }

      &.top {
        top: 0;
      }
      &.bottom {
        top: ptr(232);
        .marquee_background {
          animation: marquee-right 50s linear infinite;
        }
      }
    }
  }
}

html[is_mobile="true"] section.creator {
  @include flex(center, center, column);
  padding: ptr(100) 0 ptr(90);
  background: $purple-100;
  gap: ptr(60);

  .content {
    @include flex(center, center, column);
    width: 100%;
    padding: 0 ptr(32);
    color: $pink-20;
    text-align: center;
    gap: ptr(16);

    .data {
      @include flex(center, center);
      gap: ptr(12);
      padding: 0 0 ptr(8) ptr(16);
      font-size: ptr(100) !important;
      .cross_icon {
        width: ptr(40);
        height: ptr(40);
      }
    }
    .description {
      @include flex(center, center, column);
      text-align: center;
      span {
        font-weight: bold;
      }
    }
  }

  .creators {
    position: relative;
    flex: 1 0 0;
    align-self: stretch;
    width: 100%;
    gap: ptr(8);
    @include flex(center, center, column);
    ul {
      opacity: 0.6;
      list-style: none;
      gap: ptr(8);
      @include flex(center, center);
      img {
        width: ptr(128);
        height: ptr(128);
      }
    }
  }
}
