@import "base";
.icon {
  display: inline-flex;
  width: ptr(20);
  height: ptr(20);
  margin-right: ptr(12);
}

.menu {
  color: $black !important;

  &[data-active="true"],
  &:hover {
    color: $blue-100 !important;
  }

  .title {
    display: inline-flex;
    align-items: center;
  }

  .title_suffix {
    margin-left: ptr(4);
  }

  .title_icon_suffix {
    display: inline-flex;
    width: ptr(16);
    height: ptr(16);
    margin-left: ptr(4);
  }

  // TODO New 뱃지 없어지면 지우기 검색
  .title_suffix .new_badge {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 0.5px 4px;
    border-radius: 4px;
    background-color: $blue-10;
    color: $blue-100;
  }
}

html[data-marketplace_name="MARPPLE_SHOP"] {
  .menu_items {
    display: flex;
    flex-direction: column;
    padding-top: ptr(16) !important;
  }

  .menu {
    color: $gray-80 !important;

    &[data-active="true"],
    &:hover {
      color: $blue-100 !important;
    }
  }

  .menu_top {
    > li {
      list-style-type: none;
    }
  }

  .menu_bottom {
    padding-top: ptr(32) !important;
  }

  .live_chat {
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: ptr(100);
    height: ptr(48);
    margin: ptr(8) ptr(16) ptr(16);
    border-radius: ptr(8);
    background-color: $blue-10;
    color: $blue-100;
  }

  .onboarding {
    min-width: ptr(178);
    height: ptr(72);
    margin: 0 ptr(16);
    list-style: none;
  }
}

html[data-marketplace_name="MARPPLE_SHOP"][is_mobile="true"] {
  .menu_items {
    justify-content: flex-start;
    min-height: unset;
  }

  .menu_top {
    padding-top: ptr(40) !important;
  }

  .menu_bottom {
    padding-top: ptr(48) !important;
  }
}
