@import "base";
@import "../../../../MarppleShop/shared/style/base";
@import "./variables";

// 공통
.page {
  background: inherit;
}

.title_desc {
  color: $gray-80;
  &.hide {
    display: none;
  }
  .percent {
    color: $black;
    &.plus {
      color: $blue-100;
    }
  }
}

.custom_tooltip_container {
  display: flex;
  position: absolute;
  z-index: 9999;
  box-sizing: border-box;
  flex-direction: column;
  min-width: 171px;
  padding: 12px 16px 16px 16px;
  border: 1px solid $blue-10;
  border-radius: 8px;
  opacity: 0;
  background-color: $bright-20;
  pointer-events: none;
  row-gap: 4px;
  -webkit-backdrop-filter: blur(15px);
  backdrop-filter: blur(15px);

  .custom_tooltip_top {
    color: $gray-80;
  }

  .custom_tooltip_row_wrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: $gray-80;
  }

  .custom_tooltip_bottom {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    color: $blue-100;
  }
}

.dim_container {
  display: block;
  position: absolute;
  z-index: 999;
  overflow: hidden;

  transition: opacity 0.4s cubic-bezier(0.22, 1, 0.36, 1) 0.05s;
  border-radius: 16px;
  opacity: 0;
  background-color: rgba(164, 200, 255, 0.4);
  pointer-events: all;
  inset: 0;
  backdrop-filter: blur(0.5px);

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: -50%;
    width: 70%;
    height: 100%;
    animation: shine 1.5s infinite ease-in-out;
    background: linear-gradient(
      90deg,
      rgba(164, 200, 255, 0) 0%,
      rgba(222, 235, 255, 0.4) 30%,
      rgba(240, 248, 255, 0.6) 50%,
      rgba(222, 235, 255, 0.4) 70%,
      rgba(164, 200, 255, 0) 100%
    );
    mix-blend-mode: overlay;
  }
}

@keyframes shine {
  0% {
    left: -50%;
  }

  100% {
    left: 150%;
  }
}

// pc
html[is_mobile="false"] {
  .right_content {
    display: flex;
    height: ptr(62);
    column-gap: 8px;

    .excel_button {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0 ptr(24);
      column-gap: 4px;
      border: 1px solid $gray-10;
      border-radius: 4px;
      background-color: $white;
      color: $gray-80;
      &.disabled {
        border: none;
        background-color: $gray-5;
        color: $gray-50;
        pointer-events: none;
      }

      .excel_icon_wrap {
        display: inline-block;
        width: ptr(20);
        height: ptr(20);
        color: $green-100;
        &.disabled {
          color: $gray-50;
        }
      }
    }

    .type_filter_wrap {
      position: relative;
      .type_filter_button {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        padding: 0 ptr(24);
        column-gap: 4px;
        border: 1px solid $gray-10;
        border-radius: 4px;
        background-color: $white;
        color: $gray-80;
        &.active {
          color: $blue-100;
        }

        .type_filter_icon_wrap {
          display: inline-block;
          width: ptr(20);
          height: ptr(20);
          color: inherit;
        }
      }
    }

    &.disabled {
      background: inherit;
    }
  }

  .overview {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 16px 24px;
    border-radius: 16px;
    background-color: $gray-1;
    color: $gray-80;
    .date_picker_preset {
      background-color: inherit;
    }
    .blue {
      color: $blue-100;
    }
  }

  .body {
    display: flex;
    flex-direction: column;
    row-gap: 24px;
  }

  .section {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: ptr(24);
    border-radius: 16px;
    background-color: $gray-1;
    row-gap: ptr(16);
  }

  .type_rankings {
    width: 100%;
    min-height: ptr(278);
  }
}

// mo
html[is_mobile="true"] {
  .right_content {
    display: flex;
    flex-direction: column;
    row-gap: 8px;
    .dropdownWrap_level1 {
      position: relative;
      z-index: 2000;
      & > div {
        width: 100%;
        & > button {
          width: 100%;
        }
      }
    }
    .dropdownWrap_level2 {
      position: relative;
      z-index: 1999;
      min-height: 40px;
    }
  }

  .overview {
    margin: 0 16px;
    padding: 16px;
    border-radius: 8px;
    background-color: $gray-1;
    color: $gray-80;
    .date_picker_preset {
      background-color: inherit;
    }
    .blue {
      color: $blue-100;
    }
  }

  .body {
    display: grid;
    grid-template-columns: 1fr;
    gap: ptr(44);
  }

  .section {
    background: inherit;
  }
}
