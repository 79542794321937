@import "base";
html[is_mobile="false"] section.container {
  position: relative;
  width: ptr(708);
  padding: ptr(32);
  overflow: hidden;
  border-radius: ptr(24);
  background-color: $blue-100;
  gap: ptr(24);
  @include flex(flex-start, flex-start, column);

  .coin_image {
    position: absolute;
    top: 9px;
    right: -12px;
    width: ptr(120);
    height: ptr(120);
    transform: rotate(-15deg);
    img {
      width: 100%;
      height: 100%;
    }
  }

  .coin_image_2 {
    position: absolute;
    top: ptr(110);
    right: ptr(60);
    width: ptr(100);
    height: ptr(100);
    img {
      width: 100%;
      height: 100%;
    }
  }

  .content_container {
    position: relative;
    z-index: 1;
    align-self: stretch;
    width: 100%;
    color: $white;
    gap: ptr(24);
    @include flex(flex-start, flex-start, column);

    .desc_wrapper {
      @include flex(space-between, flex-start, column);
      gap: ptr(16);

      .desc_list {
        @include flex(flex-start, flex-start, column);
        gap: ptr(8);
        .desc_item {
          @include flex(flex-start, flex-start);
          gap: ptr(16);
          .desc_item_header {
            min-width: ptr(90);
          }
        }
      }
      .sub_desc_list {
        @include flex(flex-start, flex-start, column);
        gap: ptr(4);
        color: $bright-70;
      }
    }
  }
}

html[is_mobile="true"] section.container {
  position: relative;
  z-index: 1;
  max-width: ptr(450);
  padding: ptr(24);
  overflow: hidden;
  border-radius: ptr(24);
  background-color: $blue-100;
  gap: ptr(24);
  @include flex(flex-start, flex-start, column);

  .coin_image {
    position: absolute;
    top: 9px;
    right: -12px;
    width: ptr(110);
    height: ptr(110);
    transform: rotate(-15deg);
    img {
      width: 100%;
      height: 100%;
    }
  }

  .coin_image_2 {
    position: absolute;
    top: ptr(80);
    right: ptr(40);
    width: ptr(100);
    height: ptr(100);
    img {
      width: 100%;
      height: 100%;
    }
  }

  .content_container {
    position: relative;
    align-self: stretch;
    width: 100%;
    color: $white;
    gap: ptr(24);
    @include flex(flex-start, flex-start, column);

    .desc_wrapper {
      @include flex(space-between, flex-start, column);
      gap: ptr(16);
      .desc_list {
        @include flex(flex-start, flex-start, column);
        gap: ptr(8);
        .desc_item {
          @include flex(flex-start, flex-start);
          gap: ptr(16);
          .desc_item_header {
            min-width: ptr(90);
          }
        }
      }
      .sub_desc_list {
        @include flex(flex-start, flex-start, column);
        gap: ptr(4);
        color: $bright-70;
      }
    }
  }
}
