@import "base";
@import "../../../../../shared/style/base";

.LimitedSaleSelectField {
  display: inline-block;
  margin-top: ptr(8);

  .footer_text {
    margin-top: ptr(6);
    color: $gray-50;
  }

  span.badge {
    display: inline-flex;
    width: fit-content;
    height: ptr(20);
    margin: 0 ptr(2);

    // 일단 임시로
    &.ness_badge {
      align-items: center;
      padding: 0 ptr(2);
      border-radius: 4px;
      background-color: $black;
      color: $white;
      font-family: "Pragmatica-ExtendedBold", Pretendard, sans-serif;
      font-size: 0.8rem;
      font-weight: 600;
    }
  }
}
