@import "base";
html[is_mobile="false"] section.main {
  position: relative;
  height: 100vh;
  background-image: url("//s3.marpple.co/files/u_4648126/2025/2/original/be43f1cf46bf26b5748c6bed7b96e97ddc5468572.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;

  .content {
    position: absolute;
    top: 75%;
    left: 50%;
    width: 100%;
    transform: translate(-50%, -50%);
    color: $white;
    text-align: center;
    gap: ptr(16);
    @include flex(center, center, column);
  }
}

html[is_mobile="true"] section.main {
  position: relative;
  height: 100vh;
  background-image: url("//s3.marpple.co/files/u_4648126/2025/2/original/66513781d23c9febff24ac8cea42f68cf7f9f10a1.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;

  .content {
    position: absolute;
    top: 75%;
    left: 50%;
    width: 100%;
    transform: translate(-50%, -50%);
    color: $white;
    text-align: center;
    gap: ptr(12);
    @include flex(center, center, column);
  }
}
