@import "base";
html[is_mobile="false"] section.faq {
  @include flex(center, center, column);
  padding: ptr(120) ptr(188);
  gap: ptr(60);
  .title {
    text-align: center;
  }
  .faq_item_list {
    @include flex(center, flex-start, column);
    width: 100%;
    .faq_item {
      @include flex(center, flex-start, column);
      gap: ptr(16);
      width: 100%;
      padding: ptr(32) 0;
      border-top: 1px solid $blue-100;
      .faq_title {
        color: $blue-100;
      }
      .faq_answer_list {
        @include flex(center, flex-start, column);
        gap: ptr(8);
        .faq_answer {
          @include flex(center, flex-start);
          & .bold {
            font-weight: bold;
          }
          .requirement_item {
            @include flex(flex-start, flex-start);
            gap: ptr(8);
          }
        }
      }
    }
  }
}

html[is_mobile="true"] section.faq {
  @include flex(center, center, column);
  padding: ptr(60) ptr(32);
  gap: ptr(60);
  .title {
    text-align: center;
  }
  .faq_item_list {
    @include flex(center, flex-start, column);
    width: 100%;
    .faq_item {
      @include flex(center, flex-start, column);
      gap: ptr(8);
      width: 100%;
      padding: ptr(32) 0;
      border-top: 1px solid $blue-100;
      .faq_title {
        color: $blue-100;
      }
      .faq_answer_list {
        @include flex(center, flex-start, column);
        gap: ptr(8);
        .faq_answer {
          @include flex(center, flex-start);
          & .bold {
            font-weight: bold;
          }
          .requirement_item {
            @include flex(flex-start, flex-start);
            gap: ptr(8);
          }
        }
      }
    }
  }
}
