@import "base";
html[is_mobile="false"] {
  .form_checks {
    width: 100%;
    @include flex(flex-start, flex-start, column);

    gap: ptr(12);

    .form_check {
      position: relative;
      width: 100%;
      @include flex(flex-start, flex-start);

      gap: ptr(8);

      input[type="checkbox"] {
        width: ptr(16);
        min-width: ptr(16);
        height: ptr(16);
        margin-top: ptr(4);
        border: ptr(1) solid $gray-10;
        border-radius: ptr(4);

        &:checked {
          appearance: auto;
        }
      }

      .agree_desc {
        padding-right: ptr(40);
      }

      .btn_privacy_pop {
        position: absolute;
        top: ptr(4);
        right: ptr(4);
        color: $gray-80;
        text-decoration: underline;
        cursor: pointer;
      }
    }
  }

  .blue {
    color: $blue-100;
  }
}

html[is_mobile="true"] {
  .form_checks {
    width: 100%;
    @include flex(flex-start, flex-start, column);

    gap: ptr(12);

    .form_check {
      position: relative;
      width: 100%;
      @include flex(flex-start, flex-start);

      gap: ptr(8);

      input[type="checkbox"] {
        width: ptr(16);
        height: ptr(16);
        margin-top: ptr(4);
        border: ptr(1) solid $gray-10;
        border-radius: ptr(4);

        &:checked {
          appearance: auto;
        }
      }

      .btn_privacy_pop {
        position: absolute;
        top: ptr(4);
        right: ptr(4);
        color: $gray-80;
        text-decoration: underline;
        cursor: pointer;
      }
    }
  }

  .blue {
    color: $blue-100;
  }
}
