@import "base";
html[is_mobile="false"] {
  .mark {
    @include flex(flex-start, flex-start);

    margin: 0 ptr(6);
    padding-top: ptr(6);
  }

  .form_item {
    position: relative;
    width: 100%;
    @include flex(flex-start, flex-start, column);

    gap: ptr(8);

    .form_label {
      width: 100%;
      @include flex(flex-start, flex-start);
    }

    .form_control {
      width: 100%;
      padding: ptr(12) 0;
      border-bottom: 1px solid $gray-10;
      caret-color: $blue;
      &:focus {
        border-bottom: 1px solid $blue;
      }

      &[type="textarea"] {
        min-height: ptr(96);
        padding: ptr(12) 0;
        resize: none;
      }

      &::placeholder {
        color: $gray-50;
      }

      &.invalid {
        border-bottom: 1px solid $red-80;
      }
    }

    .form_count {
      position: absolute;
      z-index: 1;
      top: ptr(96);
      right: ptr(24);
      color: $gray-50;
      text-align: right;
    }

    .form_desc_hidden {
      display: none;
      color: $gray-50;

      &.invalid {
        display: inline;
        color: $red-80;
      }
    }
  }
}

html[is_mobile="true"] {
  .mark {
    @include flex(flex-start, flex-start);

    margin: 0 ptr(6);
    padding-top: ptr(6);
  }

  .form_item {
    position: relative;
    width: 100%;
    @include flex(flex-start, flex-start, column);

    gap: ptr(8);

    .form_label {
      width: 100%;
      @include flex(flex-start, flex-start);
    }

    .form_control {
      width: 100%;
      padding: ptr(12) 0;
      border-bottom: 1px solid $gray-10;
      caret-color: $blue;
      &:focus {
        border-bottom: 1px solid $blue;
      }

      &[type="textarea"] {
        min-height: ptr(96);
        padding: ptr(12) 0;
        resize: none;
      }

      &::placeholder {
        color: $gray-50;
      }
    }

    .form_count {
      position: absolute;
      z-index: 1;
      top: ptr(96);
      right: ptr(16);
      color: $gray-50;
      text-align: right;
    }

    .form_desc_hidden {
      display: none;
      color: $gray-50;

      &.invalid {
        display: inline;
        color: $red-80;
      }
    }
  }
}
