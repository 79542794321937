@import "base";
@import "../../variables";
@import "../../../../../shared/style/base";

.container {
  width: 100%;
  height: ptr(488);
  margin: 0;
  background-color: $white;
}

html[is_mobile="true"] {
  .container {
    margin: 0;
    padding: 0 16px;
  }
}
