@import "base";
@import "../../../../shared/style/base";
@import "variables";

.header {
  display: inline-flex;
  position: relative;
  z-index: 2;
  flex-direction: column;

  &.block {
    display: flex;
  }

  &.with_padding {
    @include mobile {
      padding-right: $marppleshop-studio-layout-padding-mo;
      padding-left: $marppleshop-studio-layout-padding-mo;
    }
  }

  &.with_margin {
    @include pc {
      margin-top: ptr(40);
      margin-bottom: ptr(40);
    }
    @include mobile {
      margin-top: ptr(32);
      margin-bottom: ptr(32);
    }
  }

  .title_container {
    display: inline-flex;

    .tooltip_content {
      width: ptr(400);
      padding: ptr(24);
    }

    .tooltip_trigger {
      display: inline-block;
      width: ptr(20);
      aspect-ratio: 1 / 1;
    }
  }

  .description {
    margin-top: ptr(8);
    color: $gray-80;
  }

  .suffix {
    margin-left: ptr(8);
    padding: ptr(4) 0;
  }

  .guide_icon_wrapper {
    display: inline-flex;
    width: ptr(20);
    height: ptr(20);
    cursor: pointer;

    &:hover {
      color: $blue;
    }
  }

  @include pc {
    .right_content {
      position: absolute;
      top: 50%;
      right: 0;
      transform: translateY(-50%);
    }
  }
  @include mobile {
    .right_content {
      margin-top: ptr(16);
    }
  }
}

.body {
  display: grid;
  grid-gap: ptr(24) ptr(20);
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: auto;

  .column3 {
    grid-column: span 3;
  }
}

.studio_h1 {
  display: inline-block;
}

.studio_h3 {
  display: block;
  margin-bottom: ptr(12);
  color: $black;
}
