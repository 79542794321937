@import "base";
@import "../../../../../shared/style/base";

.field_group {
  flex-direction: column;
  gap: ptr(8);
  width: 100%;
  margin-bottom: 2rem;

  &.hidden {
    display: none !important;
  }
}

.label_wrap {
  display: flex;
  align-items: center;
  gap: ptr(4);
  margin-bottom: ptr(8);
}

.required {
  color: $blue;
  &.hidden {
    display: none !important;
  }
}

.hidden {
  display: none !important;
}
