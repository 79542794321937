@import "base";
@import "../../../../../shared/style/base";

input.input {
  width: 100%;
  padding: ptr(11);
  border: 1px solid #d5dbe0;
  border-radius: ptr(4);

  &:disabled {
    background-color: #f8f8f8;
    color: #a2a2a2;
  }

  &:focus {
    border: 1px solid $blue-100;
  }

  &.input_error {
    border: 1px solid $red-80 !important;
  }
}
