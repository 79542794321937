@import "base";
@import "../../variables";
@import "../../../../../shared/style/base";

html[is_mobile="false"] .container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(0, 1fr));
  gap: ptr(24);
  width: 100%;
  height: ptr(268);

  .card {
    align-self: stretch;
    width: 100%;
    padding: ptr(24);
    border-radius: ptr(16);
    background-color: $white;
    gap: ptr(24);
    @include flex(flex-start, flex-start, column);

    .header {
      @include flex(flex-start, center);
      gap: ptr(8);
      width: 100%;
      pointer-events: none;

      &.sold {
        pointer-events: auto;
        @include flex(space-between, center);

        .total_count {
          letter-spacing: -0.2px;
          text-decoration-line: underline;
          text-decoration-style: solid;
          text-decoration-skip-ink: none;
          text-underline-position: from-font;
          text-decoration-thickness: ptr(1);
        }
      }
    }

    .body {
      @include flex(flex-start, center, column);
      width: 100%;
      padding: ptr(8) 0;
      gap: ptr(20);

      .item {
        @include flex(flex-start, center);
        gap: ptr(16);
        width: 100%;
        .item_ranking {
          @include flex(flex-start, center);
          width: ptr(24);
          height: ptr(24);
        }
        .item_body {
          width: 100%;
          @include flex(flex-start, center);
          gap: ptr(8);
          .thumbnail_wrap {
            flex-shrink: 0;
            width: ptr(40);
            height: ptr(40);
            overflow: hidden;
            border-radius: ptr(4);
          }

          .item_content {
            width: 100%;
            @include flex(space-between, center);
            gap: ptr(24);
            .item_name {
              display: -webkit-box;
              width: 100%;
              overflow: hidden;
              text-align: left;
              text-overflow: ellipsis;
              word-break: break-all;
              -webkit-line-clamp: 1;
              -webkit-box-orient: vertical;
            }
            .item_count {
              @include flex(flex-start, center);
            }
          }
        }
      }
    }

    .empty_body {
      width: 100%;
      height: 100%;
      @include flex(center, center, column);
      gap: ptr(8);
      img {
        width: ptr(88);
        height: ptr(88);
        opacity: 0.6;
      }
    }
  }
}

html[is_mobile="true"] .container {
  @include flex(center, center, column);
  gap: ptr(24);
  width: 100%;

  .card {
    width: 100%;
    height: ptr(260);
    padding: ptr(24) ptr(16);
    border-radius: ptr(16);
    background-color: $white;
    gap: ptr(24);
    @include flex(flex-start, flex-start, column);

    .header {
      @include flex(space-between, center);
      gap: ptr(8);
      width: 100%;
      pointer-events: none;

      &.sold {
        pointer-events: auto;

        .total_count {
          letter-spacing: -0.2px;
          text-decoration-line: underline;
          text-decoration-style: solid;
          text-decoration-skip-ink: none;
          text-underline-position: from-font;
          text-decoration-thickness: ptr(1);
        }
      }
    }

    .body {
      @include flex(flex-start, center, column);
      width: 100%;
      gap: ptr(20);

      .item {
        @include flex(flex-start, center);
        gap: ptr(16);
        width: 100%;
        .item_ranking {
          @include flex(flex-start, center);
          width: ptr(24);
          height: ptr(24);
        }
        .item_body {
          width: 100%;
          @include flex(flex-start, center);
          gap: ptr(8);
          .thumbnail_wrap {
            flex-shrink: 0;
            width: ptr(40);
            height: ptr(40);
            overflow: hidden;
            border-radius: ptr(4);
          }

          .item_content {
            width: 100%;
            @include flex(space-between, center);
            gap: ptr(24);
            .item_name {
              display: -webkit-box;
              width: 100%;
              overflow: hidden;
              text-align: left;
              text-overflow: ellipsis;
              word-break: break-all;
              -webkit-line-clamp: 1;
              -webkit-box-orient: vertical;
            }
            .item_count {
              @include flex(flex-start, center);
            }
          }
        }
      }
    }

    .empty_body {
      width: 100%;
      height: 100%;
      @include flex(center, center, column);
      gap: ptr(8);
      color: $gray-50;
      img {
        width: ptr(88);
        height: ptr(88);
        opacity: 0.6;
      }
    }
  }
}
