@import "base";
.overViewMo {
  display: flex;
  flex-direction: column;
  margin-top: 8px;
  padding: 16px;
  border-radius: 8px;
  background-color: $gray-1;
  row-gap: 8px;

  .overViewRow {
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-height: 24px;
  }

  .overViewTitle {
    color: $gray-80;
  }

  .overViewContent {
    color: $black;
  }

  @keyframes shimmer {
    from {
      background-position: 200% 0;
    }
    to {
      background-position: -200% 0;
    }
  }

  @keyframes shimmer-width {
    0% {
      width: ptr(100);
    }
    50% {
      width: ptr(130);
    }
    100% {
      width: ptr(100);
    }
  }

  .skeleton {
    height: 22px;
    animation:
      shimmer-width 2s cubic-bezier(0.4, 0, 0.2, 1) infinite,
      shimmer 2s ease-in-out infinite;
    border-radius: 4px;
    background: linear-gradient(90deg, #f0f0f0 0%, #f8f8f8 20%, #fafafa 40%, #f8f8f8 60%, #f0f0f0 100%);
    background-size: 200% 100%;
  }
}
