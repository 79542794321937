@import "base";
html[is_mobile="false"] .page {
  position: relative;
  width: 100%;
  min-width: ptr(1280);
  padding-top: $default-header-pc_height;
  overflow: hidden;
  background-color: #eff5ff;

  gap: ptr(24);
  @include flex(center, center, column);

  .text_input {
    width: 100%;

    div {
      display: flex !important;
    }
  }

  .info {
    position: relative;
    max-width: ptr(708);
    @include flex(center, center, column);

    gap: ptr(24);
    padding-top: $default-header-pc_height;
    text-align: center;

    .title_wrapper {
      @include flex(center, center, column);

      gap: ptr(16);

      .title {
        color: $blue-100;
      }
    }

    .description_wrapper {
      @include flex(center, center, column);

      gap: ptr(8);
      color: $gray-80;
    }
  }

  .ad_info {
    position: relative;
    max-width: ptr(708);
    @include flex(center, center, column);
    gap: ptr(16);
    padding-top: $default-header-pc_height;
    text-align: center;

    .title_wrapper {
      position: relative;
      width: 100%;
      @include flex(center, center, column);
      gap: ptr(24);
      .title {
        color: $blue-100;
      }
      .sub_title {
        color: $gray-80;
      }
    }

    video.motion_video {
      max-width: ptr(708);
    }

    .description {
      margin-top: ptr(6);
      margin-bottom: ptr(44);
      color: $blue-100;
    }
  }

  .ad_sub_info {
    position: relative;
    max-width: ptr(708);
    margin-top: ptr(24);
    color: $blue-100;
    text-align: center;
    gap: ptr(10);
    @include flex(center, center, column);
  }

  .ad_res {
    position: relative;
    max-width: ptr(708);
    padding-bottom: ptr(80);

    .blue {
      color: $blue-100;
    }

    .necessary {
      @include flex(flex-end, flex-start);
      position: relative;
      width: 100%;
      margin-bottom: ptr(16);
      color: $blue-100;

      &.necessary_info {
        position: absolute;
        top: ptr(32);
        right: ptr(32);
      }
    }

    .mark {
      @include flex(flex-start, flex-start);

      margin: 0 ptr(6);
      padding-top: ptr(6);
    }

    .form {
      position: relative;
      width: 100%;
      @include flex(center, center, column);

      gap: ptr(32);

      .fieldset {
        position: relative;
        width: 100%;
        @include flex(center, flex-start, column);

        gap: ptr(32);
        padding: ptr(32);
        border-radius: ptr(24);
        background-color: $white;

        .form_item {
          position: relative;
          width: 100%;
          @include flex(flex-start, flex-start, column);

          gap: ptr(8);

          .form_label {
            width: 100%;
            @include flex(flex-start, flex-start);
          }

          .form_control {
            width: 100%;
            padding: ptr(12) 0;
            border-bottom: 1px solid $gray-10;
            caret-color: $blue;
            &:focus {
              border-bottom: 1px solid $blue;
            }

            &[type="textarea"] {
              min-height: ptr(96);
              padding: ptr(12) 0;
              resize: none;
            }

            &::placeholder {
              color: $gray-50;
            }

            &.invalid {
              border-bottom: 1px solid $red-80;
            }
          }

          .cate_list {
            @include flex(flex-start, flex-start);

            flex-wrap: wrap;
            align-self: stretch;
            padding-top: ptr(8);
            gap: ptr(8);

            .cate {
              @include flex(center, center);

              padding: ptr(8) ptr(16);
              border: 1px solid $gray-10;
              border-radius: ptr(8);
              background: $white;
              color: $gray-50;
              cursor: pointer;

              &.selected {
                border: 1px solid $blue-100;
                color: $blue-100;
              }
            }
          }

          .form_count {
            position: absolute;
            z-index: 1;
            top: ptr(96);
            right: ptr(24);
            color: $gray-50;
            text-align: right;
          }

          .form_desc {
            color: $gray-50;

            &.invalid {
              color: $red-80;
            }
          }

          .form_desc_hidden {
            display: none;
            color: $gray-50;

            &.invalid {
              display: inline;
              color: $red-80;
            }
          }

          .form_file {
            display: inline;

            .form_file_label {
              @include flex(center, center);

              width: ptr(200);
              height: ptr(48);
              padding: ptr(9) ptr(24);
              border-radius: ptr(8);
              background-color: $blue-10;
              backdrop-filter: blur(20px);
              color: $blue-100;
              cursor: pointer;
            }

            input[type="file"] {
              width: 0;
              height: 0;
              opacity: 0;
            }
          }

          ul.file_list {
            @include flex(flex-start, flex-start, column);

            gap: ptr(8);

            li {
              @include flex(flex-start, center);

              gap: ptr(8);

              button.btn_delete_file {
                @include flex(center, center);

                padding: ptr(4) ptr(6);
                border-radius: ptr(4);
                background-color: $red-10;
                color: $red-80;
              }
            }
          }
        }

        .form_checks {
          width: 100%;
          @include flex(flex-start, flex-start, column);

          gap: ptr(12);

          .form_check {
            position: relative;
            width: 100%;
            @include flex(flex-start, flex-start);

            gap: ptr(8);

            input[type="checkbox"] {
              width: ptr(16);
              min-width: ptr(16);
              height: ptr(16);
              margin-top: ptr(4);
              border: ptr(1) solid $gray-10;
              border-radius: ptr(4);

              &:checked {
                appearance: auto;
              }
            }

            .agree_desc {
              padding-right: ptr(40);
            }

            .btn_privacy_pop {
              position: absolute;
              top: ptr(4);
              right: ptr(4);
              color: $gray-80;
              text-decoration: underline;
              cursor: pointer;
            }
          }
        }

        .noti {
          @include flex(flex-start, flex-start, column);

          padding: ptr(24);
          border-radius: ptr(8);
          gap: ptr(8);
          background-color: $gray-5;

          .noti_title {
            @include flex(flex-start, center);

            gap: ptr(4);

            svg {
              width: ptr(20);
              height: ptr(20);
            }
          }

          .noti_list {
            .noti_list_item {
              @include flex(flex-start, flex-start);

              color: $gray-80;
              list-style: none;
              gap: ptr(8);

              .bullet {
                @include flex(center, center);

                height: ptr(22);

                svg {
                  width: ptr(2);
                  height: ptr(2);
                }
              }
            }
          }
        }

        .portfolio_desc {
          padding-top: ptr(4);
          color: $gray-50;
        }
      }

      .form_submit {
        position: relative;
        @include flex(center, center);

        width: 100%;
        padding: ptr(16) ptr(24);
        border-radius: ptr(12);
        background-color: $blue-100;
        cursor: pointer;

        .form_submit_btn {
          width: 100%;
          color: $white;
        }

        &.disabled {
          background-color: $gray-5;

          .form_submit_btn {
            color: $gray-50;
          }
        }
      }
    }
  }

  .background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    //height: 100%;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}

html[is_mobile="true"] .page {
  position: relative;
  width: 100%;
  padding: ptr(64) ptr(16) ptr(80);
  overflow: hidden;
  background-color: #ebf3fe;
  gap: ptr(24);
  @include flex(center, center, column);
  .text_input {
    width: 100%;

    div {
      display: flex !important;
    }
  }

  .info {
    z-index: 1;
    max-width: ptr(450);
    padding-top: $default-header-mo_height;
    text-align: center;
    gap: ptr(24);
    @include flex(center, center, column);

    .title_wrapper {
      @include flex(center, center, column);

      gap: ptr(16);

      .title {
        color: $blue-100;
      }
    }

    .description_wrapper {
      @include flex(center, center, column);

      gap: ptr(8);
      color: $gray-80;
    }
  }

  .ad_info {
    z-index: 1;
    max-width: ptr(450);
    @include flex(center, center, column);
    gap: ptr(24);
    padding-top: $default-header-pc_height;
    text-align: center;

    .title_wrapper {
      position: relative;
      width: 100%;
      @include flex(center, center, column);
      gap: ptr(24);
      .title {
        color: $blue-100;
      }
      .sub_title {
        color: $gray-80;
      }
    }

    video.motion_video {
      width: ptr(344);
    }

    .description {
      margin-top: ptr(6);
      margin-bottom: ptr(44);
      color: $blue-100;
    }
  }

  .ad_sub_info {
    z-index: 1;
    max-width: ptr(450);
    margin-top: ptr(24);
    color: $blue-100;
    text-align: center;
    gap: ptr(10);
    @include flex(center, center, column);
  }

  .ad_res {
    z-index: 1;
    width: 100%;

    .blue {
      color: $blue-100;
    }

    .necessary {
      @include flex(flex-end, flex-start);

      width: 100%;
      margin-bottom: ptr(16);
      color: $blue-100;

      &.necessary_info {
        position: absolute;
        top: ptr(24);
        right: ptr(24);
      }
    }

    .mark {
      @include flex(flex-start, flex-start);

      margin: 0 ptr(6);
      padding-top: ptr(6);
    }

    .form {
      width: 100%;
      @include flex(center, center, column);

      gap: ptr(32);

      .fieldset {
        position: relative;
        width: 100%;
        @include flex(center, flex-start, column);

        gap: ptr(32);
        padding: ptr(32);
        border-radius: ptr(24);
        background-color: $white;

        .form_item {
          position: relative;
          width: 100%;
          @include flex(flex-start, flex-start, column);

          gap: ptr(8);

          .form_label {
            width: 100%;
            @include flex(flex-start, flex-start);
          }

          .form_control {
            width: 100%;
            padding: ptr(12) 0;
            border-bottom: 1px solid $gray-10;
            caret-color: $blue;
            &:focus {
              border-bottom: 1px solid $blue;
            }

            &[type="textarea"] {
              min-height: ptr(96);
              padding: ptr(12) 0;
              resize: none;
            }

            &::placeholder {
              color: $gray-50;
            }
          }

          .cate_list {
            @include flex(flex-start, flex-start);

            flex-wrap: wrap;
            align-self: stretch;
            padding-top: ptr(8);
            gap: ptr(8);

            .cate {
              @include flex(center, center);

              padding: ptr(8) ptr(16);
              border: 1px solid $gray-10;
              border-radius: ptr(8);
              background: $white;
              color: $gray-50;
              cursor: pointer;

              &.selected {
                border: 1px solid $blue-100;
                color: $blue-100;
              }
            }
          }

          .form_count {
            position: absolute;
            z-index: 1;
            top: ptr(96);
            right: ptr(16);
            color: $gray-50;
            text-align: right;
          }

          .form_desc {
            color: $gray-50;

            &.invalid {
              color: $red-80;
            }
          }

          .form_desc_hidden {
            display: none;
            color: $gray-50;

            &.invalid {
              display: inline;
              color: $red-80;
            }
          }

          .form_file {
            display: inline;

            .form_file_label {
              @include flex(center, center);

              width: ptr(200);
              height: ptr(48);
              padding: ptr(9) ptr(24);
              border-radius: ptr(8);
              background-color: $blue-10;
              backdrop-filter: blur(20px);
              color: $blue-100;
            }

            input[type="file"] {
              width: 0;
              height: 0;
              opacity: 0;
            }
          }

          ul.file_list {
            @include flex(flex-start, flex-start, column);

            gap: ptr(8);

            li {
              @include flex(flex-start, center);

              gap: ptr(8);

              button.btn_delete_file {
                @include flex(center, center);

                padding: ptr(4) ptr(6);
                border-radius: ptr(4);
                background-color: $red-10;
                color: $red-80;
              }
            }
          }
        }

        .form_checks {
          width: 100%;
          @include flex(flex-start, flex-start, column);

          gap: ptr(12);

          .form_check {
            position: relative;
            width: 100%;
            @include flex(flex-start, flex-start);

            gap: ptr(8);

            input[type="checkbox"] {
              width: ptr(16);
              height: ptr(16);
              margin-top: ptr(4);
              border: ptr(1) solid $gray-10;
              border-radius: ptr(4);

              &:checked {
                appearance: auto;
              }
            }

            .btn_privacy_pop {
              position: absolute;
              top: ptr(4);
              right: ptr(4);
              color: $gray-80;
              text-decoration: underline;
              cursor: pointer;
            }
          }
        }

        .noti {
          @include flex(flex-start, flex-start, column);

          padding: ptr(24);
          border-radius: ptr(8);
          gap: ptr(8);
          background-color: $gray-5;

          .noti_title {
            @include flex(flex-start, center);

            gap: ptr(4);

            svg {
              width: ptr(20);
              height: ptr(20);
            }
          }

          .noti_list {
            .noti_list_item {
              @include flex(flex-start, flex-start);

              color: $gray-80;
              list-style: none;
              gap: ptr(8);

              .bullet {
                @include flex(center, center);

                height: ptr(22);

                svg {
                  width: ptr(2);
                  height: ptr(2);
                }
              }
            }
          }
        }

        .portfolio_desc {
          padding-top: ptr(4);
          color: $gray-50;
        }
      }

      .form_submit {
        @include flex(center, center);

        width: 100%;
        padding: ptr(16) ptr(24);
        border-radius: ptr(12);
        background-color: $blue-100;

        .form_submit_btn {
          color: $white;
        }

        &.disabled {
          background-color: $gray-5;

          .form_submit_btn {
            color: $gray-50;
          }
        }
      }
    }
  }

  .background {
    position: absolute;
    z-index: 0;
    top: 0;
    left: 0;
    width: 100%;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .creative_creator {
    position: absolute;
    top: ptr(220);
    left: ptr(-50);
    width: ptr(120);
    height: ptr(120);
    transform: rotate(-15deg) scaleX(-1);
  }

  .letter_balloon {
    position: absolute;
    top: ptr(130);
    right: ptr(-45);
    width: ptr(100);
    height: ptr(100);
    transform: rotate(-15deg);
    opacity: 0.6;
    filter: blur(15px);
  }
}

html[is_mobile="false"] .confirm {
  @include flex(center, center, column);

  gap: ptr(16);

  .title {
    color: $gray-80;
  }

  .content {
    width: 100%;
    padding: ptr(16);
    border-radius: ptr(16);
    background-color: $blue-10;
    color: $blue-100;
    gap: ptr(4);
    @include flex(center, center, column);
  }
}
