@import "base";
html[is_mobile="false"] section.container {
  position: relative;
  width: ptr(708);
  padding: ptr(32);
  overflow: hidden;
  border-radius: ptr(24);
  background-color: $blue-100;
  gap: ptr(24);
  @include flex(flex-start, flex-start, column);

  .coin_image {
    position: absolute;
    top: 9px;
    right: -12px;
    width: ptr(120);
    height: ptr(120);
    transform: rotate(-15deg);
    img {
      width: 100%;
      height: 100%;
    }
  }

  .coin_image_2 {
    position: absolute;
    top: ptr(110);
    right: ptr(60);
    width: ptr(100);
    height: ptr(100);
    img {
      width: 100%;
      height: 100%;
    }
  }

  .content_container {
    position: relative;
    z-index: 1;
    align-self: stretch;
    width: 100%;
    color: $white;
    gap: ptr(24);
    @include flex(flex-start, flex-start, column);
    .content_wrapper {
      @include flex(flex-start, flex-start);
      gap: ptr(80);
      .title_wrapper {
        @include flex(flex-start, flex-start, column);
        gap: ptr(16);
        align-self: stretch;
        .detail {
          text-decoration-line: underline;
          text-decoration-style: solid;
          cursor: pointer;
        }
      }
      .desc_wrapper {
        @include flex(space-around, flex-start, column);
        align-self: stretch;
        .desc_list {
          @include flex(flex-start, flex-start, column);
          gap: ptr(8);
          &.hidden_promotion {
            gap: ptr(12);
          }
          .desc_item {
            @include flex(flex-start, flex-start);
            gap: ptr(16);
            .desc_item_header {
              min-width: ptr(90);
            }
          }
        }
        .sub_desc_list {
          @include flex(flex-start, flex-start, column);
          gap: ptr(4);
          color: $bright-70;
        }
      }
    }
    .caution {
      width: 100%;
      padding: ptr(16);
      transition: background-color 0.3s ease-out;
      border-radius: ptr(12);
      background-color: $dim-10;

      .caution_title_wrapper {
        @include flex(space-between, center);
        cursor: pointer;
        .toggle_status {
          width: ptr(16);
          height: ptr(16);

          .opened {
            display: none;
          }

          .closed {
            display: block;
          }
        }
      }
      .caution_body {
        @include flex(flex-start, flex-start, column);
        height: 0;
        overflow: hidden;
        transition:
          height 0.3s ease-out,
          padding-bottom 0.3s ease-out;
        .caution_wrapper {
          @include flex(flex-start, flex-start, column);
          padding: ptr(8) 0;
          color: $bright-70;
          gap: ptr(4);
          line-height: ptr(22) !important;

          .caution_item {
            @include flex(flex-start, flex-start);
            gap: ptr(8);
            text-align: start;
            svg {
              margin-top: ptr(8);
              circle {
                fill: $white;
              }
            }
            &.caution_item_sub {
              padding-left: ptr(16);
            }
          }
        }
      }

      &.is_open {
        .caution_title_wrapper {
          .opened {
            display: block;
          }
          .closed {
            display: none;
          }
        }
      }
    }
  }
}

html[is_mobile="true"] section.container {
  position: relative;
  z-index: 1;
  max-width: ptr(450);
  padding: ptr(24);
  overflow: hidden;
  border-radius: ptr(24);
  background-color: $blue-100;
  gap: ptr(24);
  @include flex(flex-start, flex-start, column);

  .coin_image {
    position: absolute;
    top: 9px;
    right: -12px;
    width: ptr(110);
    height: ptr(110);
    transform: rotate(-15deg);
    img {
      width: 100%;
      height: 100%;
    }
  }

  .coin_image_2 {
    position: absolute;
    top: ptr(80);
    right: ptr(40);
    width: ptr(100);
    height: ptr(100);
    img {
      width: 100%;
      height: 100%;
    }
  }

  .content_container {
    position: relative;
    align-self: stretch;
    width: 100%;
    color: $white;
    gap: ptr(24);
    @include flex(flex-start, flex-start, column);
    .content_wrapper {
      @include flex(flex-start, flex-start, column);
      gap: ptr(24);
      .title_wrapper {
        @include flex(flex-start, flex-start, column);
        gap: ptr(8);
        .detail {
          text-decoration-line: underline;
          text-decoration-style: solid;
          cursor: pointer;
        }
      }
      .desc_wrapper {
        @include flex(space-around, flex-start, column);
        gap: ptr(16);
        .desc_list {
          @include flex(flex-start, flex-start, column);
          gap: ptr(8);
          &.hidden_promotion {
            gap: ptr(12);
          }
          .desc_item {
            @include flex(flex-start, flex-start);
            gap: ptr(16);
            .desc_item_header {
              min-width: ptr(90);
            }
          }
        }
        .sub_desc_list {
          @include flex(flex-start, flex-start, column);
          gap: ptr(4);
          color: $bright-70;
        }
      }
    }
    .caution {
      width: 100%;
      padding: ptr(16);
      transition: background-color 0.3s ease-out;
      border-radius: ptr(8);
      background-color: $dim-10;

      .caution_title_wrapper {
        @include flex(space-between, center);
        cursor: pointer;
        .toggle_status {
          width: ptr(16);
          height: ptr(16);

          .opened {
            display: none;
          }

          .closed {
            display: block;
          }
        }
      }
      .caution_body {
        @include flex(flex-start, flex-start, column);
        height: 0;
        overflow: hidden;
        transition:
          height 0.3s ease-out,
          padding-bottom 0.3s ease-out;
        .caution_wrapper {
          @include flex(flex-start, flex-start, column);
          padding-top: ptr(8);
          color: $bright-70;
          gap: ptr(4);

          .caution_item {
            @include flex(flex-start, flex-start);
            line-height: 141% !important;
            text-align: start;
            gap: ptr(8);
            svg {
              margin-top: ptr(8);
              circle {
                fill: $white;
              }
            }
            &.caution_item_sub {
              padding-left: ptr(16);
            }
          }
        }
      }

      &.is_open {
        .caution_title_wrapper {
          .opened {
            display: block;
          }
          .closed {
            display: none;
          }
        }
      }
    }
  }
}
