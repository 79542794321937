@import "base";
html[is_mobile="false"] section.bottom {
  min-height: ptr(480);
  @include flex(center, center);
  padding: ptr(100) ptr(80);
  background-image: url("//s3.marpple.co/files/u_4648126/2025/2/original/bb7fa0b1838d775fca2f6191d74edb47041743b11.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  .content_wrapper {
    width: fit-content;
    @include flex(center, center, column);
    gap: ptr(40);
    .content {
      @include flex(center, center, column);
      gap: ptr(24);
      color: $blue-100;
      text-align: center;
    }
    a.submit {
      max-height: ptr(64);
    }
  }
}

html[is_mobile="true"] section.bottom {
  min-height: ptr(400);
  @include flex(center, center);
  padding: ptr(96) ptr(20);
  background-image: url("//s3.marpple.co/files/u_4648126/2025/2/original/493ac92fb54a7c737245bdcf11239e4bf8a886221.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  .content_wrapper {
    @include flex(center, center, column);
    gap: ptr(40);
    .content {
      @include flex(center, center, column);
      gap: ptr(24);
      color: $blue-100;
      text-align: center;
    }
    a.submit {
      max-height: ptr(48);
    }
  }
}
