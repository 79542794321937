@import "base";
.group {
  display: flex;

  &.horizontal {
    flex-direction: row;
  }
  &.vertical {
    flex-direction: column;
  }

  .item {
    display: inline-flex;
    flex: unset;
    width: unset;
  }
}
