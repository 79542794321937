@import "base";
html[is_mobile="false"] section.recruit {
  display: flex;
  height: ptr(1080);
  .main_content {
    @include flex(center, center, column);
    gap: ptr(80);
    width: 50%;
    padding: ptr(160) 0 ptr(160) ptr(188);
    background: $blue-10;
    color: $blue-100;

    .title {
      width: 100%;
      @include flex(center, flex-start, column);
      gap: ptr(8);
    }

    .info_list {
      width: 100%;
      @include flex(center, flex-start, column);
      gap: ptr(48);
      .info_item {
        width: 100%;
        @include flex(center, flex-start, column);
        gap: ptr(24);
        .info_title {
          @include flex(center, flex-start);
          gap: ptr(8);
          .blink_icon {
            width: ptr(28);
            height: ptr(28);
          }
        }

        .sub_info_list {
          @include flex(center, flex-start, column);
          gap: ptr(8);
          .sub_info_item {
            @include flex(flex-start, flex-start);
            gap: ptr(16);
            .item_title {
              width: ptr(120);
              height: ptr(33);
              padding: ptr(5) ptr(12);
              border-radius: ptr(999);
              background: $blue-100;
              color: $white;
              text-align: center;
            }
            .item_desc {
              padding-top: ptr(5);
              &.flow {
                @include flex(center, center);
                gap: ptr(8);
                .arrow_icon_wrapper {
                  @include flex(center, center);
                  gap: ptr(8);
                }
                .arrow_icon {
                  width: ptr(16);
                  height: ptr(16);
                }
              }

              &.requirement {
                @include flex(flex-start, flex-start, column);
                gap: ptr(16);
                .requirement_list {
                  @include flex(center, flex-start, column);
                  padding: ptr(8);
                  border-radius: ptr(12);
                  background: $white;
                  gap: ptr(4);
                  .requirement_item {
                    padding: 0 ptr(8);
                    @include flex(flex-start, flex-start);
                    gap: ptr(8);
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  .commerce_creator_ad {
    position: relative;
    width: 50%;
    background: #1e81ff;
    @include flex(center, center);

    .video_wrapper {
      width: ptr(680);
      height: ptr(680);
      video {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
      }
    }
  }
}

html[is_mobile="true"] section.recruit {
  @include flex(center, center, column);
  .main_content {
    width: 100%;
    @include flex(center, center, column);
    gap: ptr(40);
    padding: ptr(60) ptr(20);
    background: $blue-10;
    color: $blue-100;

    .title {
      width: 100%;
      @include flex(center, center, column);
      gap: ptr(12);
    }

    .info_list {
      width: 100%;
      @include flex(center, flex-start, column);
      gap: ptr(32);
      .info_item {
        width: 100%;
        @include flex(center, flex-start, column);
        gap: ptr(16);
        .info_title {
          @include flex(center, flex-start);
          gap: ptr(8);
          .blink_icon {
            width: ptr(24);
            height: ptr(24);
          }
        }

        .sub_info_list {
          @include flex(center, flex-start, column);
          gap: ptr(8);
          .sub_info_item {
            @include flex(flex-start, flex-start);
            gap: ptr(16);
            .item_title {
              width: ptr(88);
              height: ptr(33);
              padding: ptr(5) ptr(12);
              border-radius: ptr(999);
              background: $blue-100;
              color: $white;
              text-align: center;
            }
            .item_desc {
              padding-top: ptr(5);
              &.flow {
                @include flex(center, flex-start, column);
                .arrow_icon_wrapper {
                  @include flex(center, center);
                  gap: ptr(8);
                }
                .arrow_icon {
                  width: ptr(16);
                  height: ptr(16);
                }
              }

              &.requirement {
                @include flex(flex-start, flex-start, column);
                gap: ptr(16);
                .requirement_list {
                  @include flex(center, flex-start, column);
                  padding: ptr(8);
                  border-radius: ptr(12);
                  background: $white;
                  gap: ptr(4);
                  .requirement_item {
                    padding: 0 ptr(8);
                    @include flex(flex-start, flex-start);
                    gap: ptr(8);
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  .commerce_creator_ad {
    position: relative;
    width: 100%;
    background: #1e81ff;
    @include flex(center, center);

    .video_wrapper {
      width: 100%;
      video {
        width: 100%;
        aspect-ratio: 1;
        object-fit: cover;
        object-position: center;
      }
    }
  }
}
