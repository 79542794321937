@import "base";
$toggle-duration: 0.28s;
$apple-easing: cubic-bezier(0.4, 0, 0.2, 1);
@keyframes slideDown {
  0% {
    max-height: 0;
    transform: translateY(-10px);
    opacity: 0;
  }
  30% {
    transform: translateY(-5px);
    opacity: 0.5;
  }
  100% {
    max-height: var(--auto-height);
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes slideUp {
  0% {
    max-height: var(--auto-height);
    transform: translateY(0);
    opacity: 1;
  }
  20% {
    opacity: 0.5;
  }
  50% {
    transform: translateY(-5px);
    opacity: 0;
  }
  100% {
    max-height: 0;
    transform: translateY(-10px);
    opacity: 0;
  }
}

html[is_mobile="false"] {
  .empty {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: ptr(160) 0;
    row-gap: ptr(36);
    .imgWrap {
      width: ptr(140);
      height: ptr(89);
      img {
        object-fit: contain;
        width: 100%;
        height: 100%;
      }
    }
    span {
      color: $gray-80;
    }
  }

  .loading {
    width: 100%;
  }

  .table {
    height: fit-content;
    min-height: unset;
  }

  .childrenContainer {
    margin: 0;
    padding: 0;
    overflow: hidden;
    transform-origin: top;
    will-change: max-height, opacity, transform;

    &[data-state="open"] {
      animation: slideDown $toggle-duration $apple-easing forwards;
    }

    &[data-state="closed"] {
      animation: slideUp $toggle-duration $apple-easing forwards;
    }
  }

  .toggle {
    transition: transform $toggle-duration $apple-easing;

    &[data-state="open"] {
      transform: rotate(-180deg);
    }

    &[data-state="closed"] {
      transform: rotate(0deg);
    }
  }
}
