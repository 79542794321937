@import "base";
html[is_mobile="false"] {
  .form_item {
    position: relative;
    width: 100%;
    @include flex(flex-start, flex-start, column);

    gap: ptr(8);

    .form_label {
      width: 100%;
      @include flex(flex-start, flex-start);
    }

    .form_desc {
      color: $gray-50;

      &.invalid {
        color: $red-80;
      }
    }

    .form_file {
      display: inline;

      .form_file_label {
        @include flex(center, center);

        width: ptr(200);
        height: ptr(48);
        padding: ptr(9) ptr(24);
        border-radius: ptr(8);
        background-color: $blue-10;
        backdrop-filter: blur(20px);
        color: $blue-100;
        cursor: pointer;
      }

      input[type="file"] {
        width: 0;
        height: 0;
        opacity: 0;
      }
    }

    ul.file_list {
      @include flex(flex-start, flex-start, column);

      gap: ptr(8);

      li {
        @include flex(flex-start, center);

        gap: ptr(8);

        button.btn_delete_file {
          @include flex(center, center);

          padding: ptr(4) ptr(6);
          border-radius: ptr(4);
          background-color: $red-10;
          color: $red-80;
        }
      }
    }
  }
}

html[is_mobile="true"] {
  .form_item {
    position: relative;
    width: 100%;
    @include flex(flex-start, flex-start, column);

    gap: ptr(8);

    .form_label {
      width: 100%;
      @include flex(flex-start, flex-start);
    }

    .form_desc {
      color: $gray-50;

      &.invalid {
        color: $red-80;
      }
    }

    .form_file {
      display: inline;

      .form_file_label {
        @include flex(center, center);

        width: ptr(200);
        height: ptr(48);
        padding: ptr(9) ptr(24);
        border-radius: ptr(8);
        background-color: $blue-10;
        backdrop-filter: blur(20px);
        color: $blue-100;
      }

      input[type="file"] {
        width: 0;
        height: 0;
        opacity: 0;
      }
    }

    ul.file_list {
      @include flex(flex-start, flex-start, column);

      gap: ptr(8);

      li {
        @include flex(flex-start, center);

        gap: ptr(8);

        button.btn_delete_file {
          @include flex(center, center);

          padding: ptr(4) ptr(6);
          border-radius: ptr(4);
          background-color: $red-10;
          color: $red-80;
        }
      }
    }
  }
}
