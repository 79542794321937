@import "base";
:global {
  @media screen and (width <= 1920px) {
    html[is_mobile="false"].commerce-shop-landing {
      font-size: calc(1vw * (14 / 19.2)) !important;
    }
  }
  @media screen and (width <= 375px) {
    html[is_mobile="true"].commerce-shop-landing {
      font-size: calc(1vw * (14 / 3.75)) !important;
    }
  }
}

html[is_mobile="false"] .container {
  position: relative;
  overflow-x: hidden;
  section {
    width: 100%;
  }

  & .submit {
    position: fixed;
    z-index: 10;
    bottom: ptr(40);
    left: 50%;
    transform: translateX(-50%);
  }
}

html[is_mobile="true"] .container {
  position: relative;
  overflow-x: hidden;

  & .submit {
    position: fixed;
    z-index: 10;
    bottom: calc(16px + env(safe-area-inset-bottom));
    left: 50%;
    transform: translateX(-50%);
  }
}
