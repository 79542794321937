@import "base";
html[is_mobile="false"] .landing_header {
  position: fixed;
  z-index: 10;
  top: 0;
  right: 0;
  left: 0;
  width: 100%;
  min-height: ptr(72);
  transform: translateY(0);
  transition:
    color 0.5s,
    transform 0.3s,
    background-color 0.5s linear;
  background-color: transparent;
  color: $white;
  will-change: transform;

  &.bg_white {
    background-color: $white !important;
    color: $blue-100 !important;
  }

  &.light {
    background-color: $white;
    color: $blue-100;
  }

  &::-webkit-scrollbar {
    display: none;
  }

  &:global(.headroom--pinned) {
    transform: translateY(0);
  }

  &:global(.headroom--unpinned) {
    transform: translateY(ptr(-72));
  }

  &:global(.headroom--top) {
    background-color: transparent;
    color: $white;
  }

  &:global(.headroom--not-top) {
    background-color: $bright-70;
    color: $blue-100;
    backdrop-filter: blur(6px);

    .popup_store_division {
      .division_item {
        color: $gray-50;

        &.active {
          color: $blue-100;
        }
      }
    }
  }

  .logo {
    display: flex;
    position: absolute;
    top: 0;
    left: ptr(24);
    align-items: center;
    height: ptr(72);

    a {
      color: inherit;
    }
  }

  .mp_logo {
    display: inline-block;
    height: ptr(32);
  }
}

html[is_mobile="true"] .landing_header {
  position: fixed;
  z-index: 10;
  top: 0;
  right: 0;
  left: 0;
  width: 100%;
  min-height: ptr(56);
  transform: translateY(0);
  transition:
    color 0.5s,
    transform 0.3s,
    background-color 0.5s linear;
  background-color: transparent;
  color: $white;
  will-change: transform;

  &.bg_white {
    background-color: $white !important;
    color: $blue-100 !important;
  }

  &.light {
    background-color: $white;
    color: $blue-100;
  }

  &::-webkit-scrollbar {
    display: none;
  }

  &:global(.headroom--pinned) {
    transform: translateY(0);
  }

  &:global(.headroom--unpinned) {
    transform: translateY(ptr(-56));

    .popup_store_division {
      display: none;
    }
  }

  &:global(.headroom--top) {
    background-color: transparent;
    color: $white;
  }

  &:global(.headroom--not-top) {
    background-color: $bright-70;
    color: $blue-100;
    backdrop-filter: blur(6px);

    .popup_store_division {
      .division_item {
        color: $blue-100;
        font-weight: 400 !important;

        &.active {
          border-bottom: 2px solid $blue-100;
          color: $blue-100;
          font-weight: 700 !important;
        }
      }
    }
  }

  .logo {
    display: flex;
    position: absolute;
    top: 0;
    left: ptr(16);
    align-items: center;
    height: ptr(56);

    a {
      display: flex;
      align-items: center;
      width: 100%;
      height: 100%;
      color: inherit;
    }
  }

  .mp_logo {
    display: inline-block;
    width: ptr(267);
  }
}
