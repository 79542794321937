@import "base";
.overViewBox {
  display: flex;
  justify-content: space-between;
  width: 100%;
  min-height: ptr(92);
  padding: ptr(16);
  border: ptr(1) solid $gray-10;
  border-radius: ptr(8);
  row-gap: ptr(4);
}

.overViewTitle {
  display: flex;
  align-items: center;
  height: ptr(22);
}

.overViewAmountWrap {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  column-gap: ptr(2);
}

@keyframes shimmer {
  from {
    background-position: 200% 0;
  }
  to {
    background-position: -200% 0;
  }
}

@keyframes shimmer-width {
  0% {
    width: ptr(100);
  }
  50% {
    width: ptr(130);
  }
  100% {
    width: ptr(100);
  }
}

.skeleton {
  height: ptr(24);
  animation:
    shimmer-width 2s cubic-bezier(0.4, 0, 0.2, 1) infinite,
    shimmer 2s ease-in-out infinite;
  border-radius: ptr(4);
  background: linear-gradient(90deg, #f0f0f0 0%, #f8f8f8 20%, #fafafa 40%, #f8f8f8 60%, #f0f0f0 100%);
  background-size: 200% 100%;
}
