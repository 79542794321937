@import "base";
html[is_mobile="false"] .section {
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  grid-template-rows: auto;
  width: 100%;
  gap: ptr(16);

  @media screen and (max-width: ptr(1920)) {
    grid-template-columns: repeat(2, minmax(0, 1fr));

    & > :last-child {
      grid-column: span 2;
    }
  }
}

html[is_mobile="true"] .section {
  width: 100%;
}
