@import "base";
@import "../../../../../shared/style/base";

.sale_date_field {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  .checkbox {
    margin-top: ptr(8);
  }

  span.badge {
    display: inline-flex;
    width: fit-content;
    height: ptr(20);
    margin: 0 ptr(2);
  }

  .footer_text {
    margin-top: ptr(6);
    color: $gray-50;
  }
}

.error_message {
  display: inline-block;
  margin-top: ptr(6);
  color: $red-80;
  &.hidden {
    display: none !important;
  }
}

.radio_group {
  display: flex;
  margin-top: ptr(8);
  gap: ptr(24);
}
