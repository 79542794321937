@import "base";
.goods_type_badge {
  width: fit-content;
  max-height: 22px;
  padding: ptr(2) ptr(6);
  border-radius: ptr(4);
  white-space: nowrap;

  &[data-goods_type_id="MPS"] {
    background-color: $blue-10;
    color: $blue-100;
  }

  &[data-goods_type_id="PBG"] {
    background-color: $green-10;
    color: $green-100;
  }

  &[data-goods_type_id="DTG"] {
    background-color: $brown-10;
    color: $brown-100;
  }

  &[data-goods_type_id="TPL"] {
    background-color: $pink-10;
    color: $red-80;
  }
}

.type_text {
  margin-right: 12px;
  color: $gray-80;
}

.typeCheckBoxWrap {
  display: flex;
  align-items: center;
  column-gap: 8px;
  & > label {
    justify-content: flex-start;
    width: 100%;
  }
}

.type_selector_box {
  display: flex;
  position: absolute;
  z-index: 1;
  top: calc(100% + 8px);
  left: 0;
  flex-direction: column;
  min-width: ptr(143);
  padding: ptr(16);
  transition: opacity 200ms ease-out;
  border: 1px solid $gray-20;
  border-radius: 4px;
  opacity: 1;
  background-color: $white;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.08);
  row-gap: ptr(8);
  &.hide {
    visibility: hidden;
    opacity: 0;
  }
  .type_selector_box_row {
    display: flex;
    align-items: center;
    column-gap: ptr(8);
  }
}
