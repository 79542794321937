@import "base";
.root {
  display: grid;
  grid-auto-columns: 1fr;
  grid-auto-flow: column;
  width: 100%;
  min-height: ptr(90);
  border: solid 1px $gray-10;
  border-radius: 8px;
  cursor: pointer;
  &.is_mobile {
    display: flex;
    width: unset;
    min-height: 64px;
    margin: 0 -20px;
    overflow: auto;
    column-gap: 8px;
    border: none;
    scrollbar-width: none;
    &::-webkit-scrollbar {
      display: none;
    }
    & .item {
      flex: 1 0 0;
      justify-content: center;
      min-width: 100px;
      padding: 0;
      overflow: hidden;
      border: 1px solid $gray-10;
      border-radius: 8px;
      &:first-child {
        margin-left: 20px;
      }
      &:last-child {
        margin-right: 20px;
      }
    }
    & .count {
      text-align: center;
    }
  }
}

.item {
  display: flex;
  align-items: center;
  padding-left: 24px;
  column-gap: 12px;
  &:not(:last-child) {
    border-right: 1px solid $gray-10;
  }

  .info {
    display: flex;
    flex-direction: column;
    .name {
      color: $gray-80;
    }
    .count {
      color: $black;
    }
    .unit {
      color: $gray-80;
    }
  }
  .icon_background {
    display: flex;
    align-items: center;
    justify-content: center;
    width: ptr(40);
    height: ptr(40);
    border-radius: 50%;
    background-color: $gray-5;
  }
  .icon_wrap {
    width: ptr(24);
    height: ptr(24);
    color: $gray-50;
  }
  &.selected {
    .name,
    .count,
    .unit {
      color: $blue-100;
    }

    .icon_background {
      background-color: $blue-100;
    }

    .icon_wrap {
      color: $white;
    }
  }
}
