@import "base";
html[is_mobile="false"] section.benefit {
  min-height: ptr(1090);
  padding: ptr(120) ptr(188);
  @include flex(center, center, column);
  gap: ptr(24);
  .title {
    text-align: center;
  }
  .benefit_list {
    @include flex(center, flex-start);
    gap: ptr(48);
    .benefit_item {
      @include flex(flex-start, center, column);
      align-self: stretch;
      padding: ptr(32);
      border: 1px solid $gray-10;
      border-radius: ptr(24);
      background: $white;
      text-align: center;
      gap: ptr(24);
      .img_wrapper {
        width: ptr(400);
        height: ptr(360);
        & img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          object-position: center;
        }
      }
      .benefit_item_content {
        width: 100%;
        @include flex(center, center, column);
        gap: ptr(16);
        .desc {
          @include flex(center, center, column);
          gap: ptr(8);
          & span {
            padding: 0 ptr(4);
            background: $blue-10;
            color: $blue-100;
            font-weight: bold;
          }
        }
      }
    }
  }
}

html[is_mobile="true"] section.benefit {
  padding: ptr(60) 0;
  @include flex(center, center, column);
  gap: ptr(24);
  .title {
    text-align: center;
  }
  .benefit_list {
    @include flex(flex-start, flex-start);
    .benefit_item {
      @include flex(flex-start, center, column);
      align-self: stretch;
      padding: ptr(32);
      border-radius: ptr(24);
      background: $white;
      text-align: center;
      gap: ptr(24);
      .img_wrapper {
        width: ptr(335);
        height: ptr(360);
        overflow: hidden;
        border-radius: ptr(16);
        & img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          object-position: center;
        }
      }
      .benefit_item_content {
        width: 100%;
        @include flex(center, center, column);
        gap: ptr(16);
        .desc {
          @include flex(center, center, column);
          gap: ptr(8);
          & span {
            padding: 0 ptr(4);
            background: $blue-10;
            color: $blue-100;
            font-weight: bold;
          }
        }
      }
    }
  }

  .pagination {
    position: relative;
    width: calc(100% - 64px);
    height: ptr(2);
    background: $dim-5;

    .progressbar_fill {
      background: $blue-100;
    }
  }
}
