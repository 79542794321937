@import "base";
@import "../../../shared/style/base";

.page {
  width: 100%;

  .header {
    margin-bottom: ptr(20);
  }

  .content {
    @include flex(center, center, column);
    flex-shrink: 1;
    width: 100%;
    padding-top: ptr(20);
    border: 1px solid $gray-5;
    background: $white;
    background-clip: padding-box;
    box-shadow: 0 1px 4px $gray-5;

    .content_header {
      @include flex(center, center);
      gap: ptr(20);
      margin-bottom: ptr(20);

      .status_button {
        .approve_button:not(:disabled) {
          color: $white;
        }
      }
    }

    .thumbnail {
      position: relative;
      width: ptr(540);
      height: ptr(540);
      cursor: pointer;

      .product_tag {
        display: flex;
        position: absolute;
        top: ptr(10);
        right: ptr(10);
        left: auto;
        gap: ptr(4);
      }
    }

    .info {
      @include flex(center, center, column);
      margin-bottom: ptr(20);
      border-top: ptr(1) solid $black;
      border-left: ptr(1) solid $black;

      .info_row {
        display: grid;
        grid-template-columns: ptr(200) ptr(200) ptr(200) ptr(200);

        .field {
          padding: ptr(10);
          border-right: ptr(1) solid $black;
          border-bottom: ptr(1) solid $black;
          background-color: $gray-20;
        }
        .value {
          padding: ptr(10);
          border-right: ptr(1) solid $black;
          border-bottom: ptr(1) solid $black;
        }
      }

      .info_row:nth-of-type(1) {
        .field {
          grid-column: 1 / 2;
        }
        .value {
          grid-column: 2 / 5;
        }
      }
    }

    .empty {
      @include flex(center, center);
      height: ptr(600);
    }
  }
}
