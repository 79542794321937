@import "base";
@import "../../../../shared/style/base";
@import "../../../../shared/style/typography";

// Variables
$datepicker-min-width: 250px;

// Mixins
@mixin dropdown-box {
  position: absolute;
  z-index: 1000;
  top: 100%;
  right: 0;
  border: 1px solid $gray-10;
  border-top: none;
  border-radius: 0 0 4px 4px;
  background: $white;
}

.datepicker {
  display: inline-block;
  position: relative;
}

.datepicker_button {
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-width: $datepicker-min-width;
  padding: 8px 12px;
  border: 1px solid $gray-10;
  border-radius: 4px;
  background: white;
  cursor: pointer;

  .text_date {
    color: $gray-80;
  }

  .text {
    color: $black;
  }

  &.open {
    border-radius: 4px 4px 0 0;
  }
}

.datepicker_button_text_wrap {
  display: flex;
  flex-direction: column;
  text-align: left;
}

.datepicker_dropdown {
  @include dropdown-box;
  visibility: hidden;
  min-width: $datepicker-min-width;
  transform: translateY(-10px);
  transition:
    transform 0.4s cubic-bezier(0.34, 1.3, 0.64, 1),
    opacity 0.2s ease,
    visibility 0.2s ease;
  opacity: 0;
  will-change: transform, opacity;

  &.open {
    visibility: visible;
    transform: translateY(0);
    opacity: 1;
  }
}

.datepicker_dropdown_hidden {
  display: none;
}

.datepicker_dropdown_item {
  display: block;
  width: 100%;
  padding: 8px 12px;
  border: none;
  background: none;
  color: $black;
  text-align: left;
  cursor: pointer;
}

.datepicker_dropdown_item:hover {
  background: $gray-5;
}

.datepicker_dropdown_item_selected {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: $blue-100;
  font-weight: 700;
}

.calendar_icon_wrap {
  width: 20px;
  height: 20px;
  color: $gray-50;
}

.check_icon_wrap {
  width: 16px;
  height: 16px;
  color: $blue-100;
}

.dropdown_item_content {
  display: flex;
  flex-direction: column;
  gap: 4px;
  pointer-events: none;
}

.date_range {
  color: #666;
  font-size: 14px;
}

.preset_label {
  color: #333;
  font-size: 14px;
}

.divider {
  height: 1px;
  background-color: #eee;
}

.calendar_wrapper {
  :global {
    .flatpickr-calendar {
      @include flex(flex-start, stretch, column);
      padding: ptr(8) ptr(16) ptr(16);
    }

    .flatpickr-months {
      @include flex(space-between, center);

      .flatpickr-prev-month,
      .flatpickr-next-month {
        padding: ptr(10);
        cursor: pointer;

        svg {
          width: ptr(16);
        }

        &.flatpickr-disabled svg path {
          fill: $gray-20;
        }
      }

      .flatpickr-month {
        padding: ptr(16) 0;

        .flatpickr-current-month {
          @include flex(center, center);
          gap: ptr(8);
          position: relative;
          padding-right: 20px;
          cursor: pointer;

          &::after {
            content: "";
            position: absolute;
            top: 50%;
            right: 0;
            width: 16px;
            height: 16px;
            transform: translateY(-50%);
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='17' height='16' viewBox='0 0 17 16' fill='none'%3E%3Cpath d='M7.93193 11.6676C8.24855 12.1429 8.94689 12.1429 9.26351 11.6676L13.5428 5.24386C13.897 4.7122 13.5159 4.00033 12.877 4.00033H4.31842C3.67959 4.00033 3.29845 4.7122 3.65263 5.24386L7.93193 11.6676Z' fill='%23141414'/%3E%3C/svg%3E");
            background-repeat: no-repeat;
            background-position: center;
            background-size: 16px 16px;
          }
        }

        .cur-month,
        .cur-year {
          color: $black;
          font-family: Pretendard;
          font-size: 20px;
          font-weight: 700;
          letter-spacing: -0.2px;
          line-height: 28px;
          text-align: center;

          &.numInput {
            cursor: none;
            pointer-events: none;
          }
        }

        .flatpickr-monthDropdown-months,
        .arrowUp,
        .arrowDown {
          display: none;
        }

        .cur-month {
          order: 1;
        }

        .numInputWrapper {
          flex: 0 0 auto; /* 내용물 크기에 맞게 고정 */
          order: 0;
          width: auto; /* 또는 구체적인 픽셀 값 지정 */

          &::after {
            content: "\B144";
            color: $black;
            font-family: Pretendard, serif;
            font-size: 20px;
            font-weight: 700;
            letter-spacing: -0.2px;
            line-height: 28px;
            text-align: center;
          }

          .numInput {
            -moz-appearance: textfield;
            width: 4ch;
            &::-webkit-outer-spin-button,
            &::-webkit-inner-spin-button {
              -webkit-appearance: none;
              margin: 0;
            }
          }
        }
      }
    }

    .flatpickr-weekdays {
      .flatpickr-weekdaycontainer {
        @include grid(7);
      }

      .flatpickr-weekday {
        @include flex(center, center);
        width: ptr(40);
        height: ptr(50);
        color: $gray-80;
        font-family: Pretendard;
        font-size: 14px;
        font-weight: 400;
        letter-spacing: -0.2px;
        line-height: 22px;
        text-align: center;
      }
    }

    .flatpickr-days {
      .dayContainer {
        @include grid(7);

        .flatpickr-day {
          @include flex(center, center);

          position: relative;
          width: 40px;
          height: 40px;
          font-family: "Neue Haas Unica Pro";
          font-size: 16px;
          font-weight: 400;
          letter-spacing: -0.2px;
          line-height: 22px;
          text-align: center;
          cursor: pointer;

          // 오늘 날짜 (Today) - 테두리만 있는 원형
          &.today:not(.endRange) {
            border: 1px solid $blue-100;
            border-radius: 9999px;
            background: inherit;
          }

          &.flatpickr-disabled {
            color: $gray-20;
          }

          &.prevMonthDay {
            visibility: hidden;
          }

          &.nextMonthDay {
            display: none;
          }

          // 일요일 (첫 번째 열)
          &:nth-child(7n + 1).inRange {
            border-radius: 999px 0 0 999px;
          }

          // 토요일 (마지막 열)
          &:nth-child(7n).inRange {
            border-radius: 0 999px 999px 0;
          }

          &.selected {
            background: $blue-10;
          }

          &.startRange,
          &.endRange {
            position: relative;
            z-index: 1;
            color: $white;

            &::before {
              content: "";
              position: absolute;
              z-index: -1;
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
              background: $blue-100;
              clip-path: circle(50% at center);
            }
            &:hover {
              background: $blue-10;
            }
          }

          &.startRange {
            border-radius: 999px 0 0 999px;
          }

          &.endRange {
            border-radius: 0 999px 999px 0;
          }

          &.inRange {
            background: $blue-10;
            color: $blue-100;
          }

          &:hover:not(.inRange):not(.startRange):not(.endRange):not(.flatpickr-disabled) {
            border-radius: 9999px;
            background: $gray-10;
          }
        }
      }
    }

    .flatpickr-monthSelect-months {
      @include grid(4);
      align-items: center; /* 세로 중앙 정렬 */
      justify-items: center; /* 가로 중앙 정렬 */

      .flatpickr-monthSelect-month {
        @include flex(center, center);
        @extend .font_unica_16;
        @include base_unica(16, calc(22 / 16), $font-weight-normal);
        width: 62px;
        height: 40px;
        padding: 4px;
        border-radius: 8px;
        color: $gray-80;
        text-align: center;
        cursor: pointer;

        &.selected {
          background-color: $blue;
          color: $white;
        }

        &:hover:not(.selected, .flatpickr-disabled) {
          background-color: $blue-10;
          color: $blue-100;
        }

        &.flatpickr-disabled {
          color: var(--Gray-20, #d9d9d9);
          cursor: not-allowed;
        }
      }
    }
  }
}

html[is_mobile="false"] {
  .calendar_container {
    @include dropdown-box;
    top: calc(100% + 8px);
    min-width: 312px;
    border: 1px solid $gray-20;
    border-radius: 4px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.08);
  }
}

html[is_mobile="true"] {
  // 모달 배경 딤 처리를 위한 오버레이
  .modal_overlay {
    position: fixed;
    z-index: 999;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: $dim-30;
  }

  // 모달 활성화시 body 스크롤 방지
  &.modal_open {
    position: fixed;
    width: 100%;
    height: 100%;
    overflow: hidden;
  }

  .calendar_btns {
    display: flex;
    justify-content: center;
    padding: 0 24px 24px;
    column-gap: 8px;
  }

  .datepicker_dropdown {
    width: 100%;
  }

  .calendar_container {
    position: fixed;
    z-index: 1000;
    top: 50%;
    left: 50%;
    width: 90%;
    max-width: 328px;
    transform: translate(-50%, -50%);
    border-radius: 16px;
    background-color: #fff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.12);
  }

  .calendar_wrapper {
    :global {
      .flatpickr-days {
        .dayContainer {
          .flatpickr-day {
            width: 42.5px;
            height: 42.5px;

            &.selected {
              position: relative;
              z-index: 1;
              color: $white;

              &::before {
                content: "";
                position: absolute;
                z-index: -1;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background: $blue-100;
                clip-path: circle(50% at center);
              }
            }
          }
        }
      }
    }
  }
}

/* DatePicker 로딩 스피너 */
@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes dash {
  0% {
    stroke-dasharray: 1, 150;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -35;
  }
  100% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -124;
  }
}

.spinner {
  transform-origin: center;
  animation: rotate 1.5s linear infinite;
}
.spinner_path {
  animation: dash 1.5s ease-in-out infinite;
  stroke-linecap: round;
  stroke: $blue-100;
}
